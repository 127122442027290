<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>

      <a-form-model-item label='视频封面' prop='videoPic' :required="true">
        <a-upload   listType='picture-card'
                    class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => fileBeforeUpload("videoPic",info)'
                    :customRequest='(info) => fileUploadRequest("videoPic",info)' v-model="form.videoPic">
          <img v-if="form.videoPic != null" :src='form.videoPic' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label='视频播放地址' prop='videoUrl' >
        <a-upload-dragger
          name="file"
          :fileList="fileList"
          :multiple="true"
          :remove="handleRemove"
          :before-upload="beforeUpload"
          :customRequest='fileListUploadRequest'>
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">
            请将文件拖拽到此处上传❥(^_-)
          </p>
          <p class="ant-upload-hint">
            支持单个上传，也可以点击后选择文件上传
          </p>
        </a-upload-dragger>
      </a-form-model-item>

      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            上传
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMusicLibrary, addMusicLibrary, updateMusicLibrary } from '@/api/dynamic/musicLibrary'
import { Log } from '@/components/crud/Log'
import UploadFileToOSS from '@/utils/upload-file-to-oss'

export default {
  name: 'CreateForm',
  props: {}
  ,
  components: {}
  ,
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      fileList: [],
      loading:false,
      // 表单参数
      form: {
        id: null,

        title: null,

        videoPic: null,

        videoUrl: null,

        videoTime: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [
          {
            required: true, message: '歌曲名称不能为空', trigger: 'blur'
          }
        ]
      }
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        title: null,
        videoPic: null,
        videoUrl: null,
        videoTime: null,
        createTime: null,
        remark: null
      };
      this.fileList = []
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '批量上传'
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMusicLibrary({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    }
    ,
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.loading = true;
          Log.info('批量上传');

          debugger
          new UploadFileToOSS(this.fileList, {
            finally: (res) => {
              Log.info('upload', res);
              this.loading = false;
              console.log("res=================")
              console.log(res)
              this.save(res);
            },
          });
        } else {
          return false
        }
      })
    },
    //后台保存
    save(files){

      const that = this;
      let data = []
      files.map(file=>{
        let fileName = file.fileName;
        data.push({'title': fileName.substr(0,fileName.indexOf(".")),'videoPic':this.form.videoPic,'videoUrl':file.url,'videoTime':file.videoTime});
      });
      console.log("===============data")
      console.log(data)
      addMusicLibrary(data).then(response => {
        this.$message.success(
          '新增成功',
          3
        )
        this.open = false
        this.$emit('ok')
      }).finally(() => {
        this.submitLoading = false
        this.open = false
        this.$emit('ok')
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {
      Log.info('uploadfileInfo', fileInfo);
      // debugger
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('upload', res);
          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url;
        },
      });
    },
    beforeUpload (files) {
      console.log(files)
      if (!files.type.includes('video')) {
        this.$message.warning('请上传视频');
        return false;
      }
      if(this.fileList.length >200){
        this.$message.warning('最大上传数量200');
        return false;
      }
      this.fileList = [...this.fileList, files]
      return false
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList

    },
    fileListUploadRequest(){
      this.loading = true;
      Log.info('批量上传');
      new UploadFileToOSS(this.fileList, {
        finally: (res) => {
          Log.info('upload', res);
          this.loading = false;
          console.log("res=================")
          console.log(res)
          this.save(res);
          // this.form.imgUrl = res[0].url;
          // this.form[field] = res[0].url;
        },
      });
    },
  }
}
</script>
